<template>
  <v-container >
    <v-layout wrap justify-center>
      <v-card class="ma-0 mt-0" width="350px">
        <v-form>
          <div>멀티게이트(GW) {{ status_text }}</div>
            
          <v-text-field dense outlined class="ma-1" style="height: 45px; width: auto;"
            id="smart_mac" 
            ref="smart_mac" 
            label="맥ID✽" 
            v-model="smart_mac"
            :loading="loadingMac" 
            :disabled="smartDisabled"
            placeholder="멀티게이트(GW)의 맥ID"
            maxlength="17"
            @keyup="smartMacMask(smart_mac)"
          />
          <v-combobox dense outlined style="height: 45px; margin: 5px"
            id="model" 
            ref="model" 
            label="모델명✽"
            v-model="model"
            :items="modelItems" 
            placeholder="식별할 수 있는 모델명(새 입력 가능)"
            @focus="listModels()"
          />
          <v-select dense outlined class="ma-1" style="height: 45px; width: auto;" v-show=false
            id="company" 
            ref="company" 
            label="경영체✽" 
            v-model="company"
            :items="companyItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다.."
            item-text="company_name"
            return-object
            @change="changeCompany()"
            @focus="comboCompanies()"
            clearable
          />
          <v-select dense outlined  class="ma-1" style="height: 45px; width: auto;" 
            id="farm" 
            ref="farm" 
            label="농장/사업장✽" 
            v-model="farm"
            :items="farmItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="farm_name"
            return-object
            @change="changeFarm()"
            @focus="comboFarms()"
            clearable
          />
          <v-select dense outlined  class="ma-1" style="height: 45px; width: auto;"
            id="building" 
            ref="building" 
            label="축사/건물/구역✽" 
            v-model="building"
            :items="buildingItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="building_name"
            return-object
            @change="changeBuilding()"
            @focus="comboBuildings()"
            clearable
          />
          <v-select dense outlined  class="ma-1" style="height: 45px; "
            id="room" 
            ref="room" 
            label="설치위치(돈방/위치)" 
            v-model="room"
            :items="roomItems"
            :menu-props="{ top: false, offsetY: true }"
            placeholder="설치된 위치(근접 돈방/위치)" 
            no-data-text="자료(권한)이 없습니다."
            item-text="room_name"
            return-object
            @change="changeRoom()"
            @focus="comboRooms()"
            clearable
          />
          <v-layout>
          <v-menu
            v-model="menuSensorSetdate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink mr-1 ml-1" style="width: 180px" 
                id="setdate" 
                ref="setdate" 
                v-model="setdate"
                label="설치일✽"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="setdate"
              @input="menuSensorSetdate = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menuSensorDeldate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink mr-1" style="width: 180px"
                id="deldate" 
                ref="deldate" 
                v-model="deldate"
                label="중지일"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="deldate"
              @input="menuSensorDeldate = false"
            ></v-date-picker>
          </v-menu>
          </v-layout>
          <v-textarea outlined dense rows="2" name="input-7-4" style="height: auto; margin: -15px 3px 0px 5px"
            label="비고"
            id="history" 
            ref="history" 
            v-model="history"
            value=""
            persistent-hint :hint="hint"
          ></v-textarea>
        
          <v-card-actions class="d-block" >
            <v-row justify= "center" >
              <v-spacer ></v-spacer>
              <v-btn rounded class="normal-btn mr-2" style="height: 30px; width: 20px;  margin-bottom: 15px"
                v-if="this.$getters.userInfo.grade < 'UT02'"
                :loading="loadingSave" 
                id="save" 
                ref="save"
                type="submit" 
                @click.prevent="save()">
                저장
              </v-btn>
              <v-spacer ></v-spacer>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>

    </v-layout>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import Common from "@/utils/custom/common.js";

export default {
  name: 'QRsmartbox',
  data: () => ({

    company : {},
    companyItems : [],
    farm : {},
    farmItems : [],
    building : {},
    buildingItems : [],
    room : {},
    roomItems : [],


    model: "",
    modelItems : [],
    smart_mac: "",
    setdate: "",
    deldate: "",
    history: "",
    hint: "",

    loadingMac: false,
    loadingSave: false,

    status_text: "정보",
    smartDisabled: true,
    menuSensorSetdate: false,
    menuSensorDeldate: false,

    msgflag: true,
  }),
  created() {
    Apis.pageCount({ //페이지뷰 카운터
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    this.comboCompanies();
    this.comboFarms();
    this.comboBuildings();
    this.comboRooms();
    this.listModels();
    this.$store.commit("resMessage","");
    
    let url = new URL(window.location.href);      // URL 파라미터 유무 검사
    let urlParams = url.searchParams;             // URLSearchParams 객체
    // URLSearchParams.get()
    let mac = urlParams.get('mac');
    this.infoDetail(mac);
    
  },

  methods: {
    clearData() {
      this.smartDisabled = true;
      this.smartbox_ID = "";
      this.smart_mac = "";
      this.slave_id = "";
      this.model = "";
      this.company = {};
      this.farm = {};
      this.building = {};
      this.room = {};
      this.setdate = "";
      this.deldate = "";
      this.history = "";
      this.hint = "";
    }, 
    checkData() {
      if (!this.smart_mac) {
        this.$refs.smart_mac.focus();
        return "연결된 멀티게이트의 맥 주소를 입력하세요";
      }
      let mac = this.smart_mac.replace(/:/gi, '')
      if (mac.length !== 12) {
        this.$refs.smart_mac.focus();
        return "멀티게이트의 맥 주소가 유효하지 않습니다.";
      }
      if (!this.model) {
        this.$refs.model.focus();
        return "스미트박스 모델 식별명을 입력하세요.";
      }
      // if (!this.company.company_cd) {
      //   this.$refs.company.focus();
      //   return "경영체를 선택입력하세요";
      // }
      if (!this.farm.farm_cd) {
        this.$refs.farm.focus();
        return "농장/사업장을 선택입력하세요";
      }
      if (!this.building.building_cd) {
        this.$refs.building.focus();
        return "축사/건물/구역을 선택입력하세요";
      }
      // if (!this.room.room_cd) {
      //   this.$refs.room.focus();
      //   return "돈방/위치을 선택입력하세요";
      // }
      if (!this.setdate) {
        this.$refs.setdate.focus();
        return "설치일을 선택입력하세요";
      }
      return "";
    },
    save() {
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        // alert(msgCheckdata);
        return;
      }
      this.loadingSave = true;
      if (this.smartbox_ID) {
        // console.log("업데이트");
        Apis.updateSmartbox({
          mac: this.smart_mac.replace(/:/gi, ''),
          model_id: this.model.trim().toUpperCase(),
          company_cd: this.company.company_cd,
          farm_cd : this.farm.farm_cd,
          building_cd :	this.building.building_cd,
          room_cd :	this.room.room_cd,
          device_name :	this.building.building_name,
          setdate :	this.setdate,
          deldate :	this.deldate,
          history : this.history.trim(),
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              this.msgFlag = false;
              this.clearData();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      } else {
        // console.log("인서트",this.company.code);
        Apis.insertSmartbox({
          mac: this.smart_mac.replace(/:/gi, ''),
          model_id: this.model.trim().toUpperCase(),
          company_cd: this.company.company_cd,
          farm_cd : this.farm.farm_cd,
          building_cd :	this.building.building_cd,
          room_cd :	this.room.room_cd,
          device_name :	this.building.building_name,
          setdate :	this.setdate,
          deldate :	this.deldate,
          history : this.history.trim(),
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.msgFlag = false;
              this.loadingSave = false;
              this.msgFlag = false;
              this.clearData();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      }
    },
    infoDetail(mac) {
      // console.log("select",value)
      this.$store.commit("resMessage","");
      this.loadingMac = true;
      this.clearData();
      this.smart_mac = Common.getMacMask(mac);
      Apis.infoSmartbox({
        mac: mac,
        },(res) => {  
          if (res.result) {
            if (res.resultCode == "W201") {
              this.$store.commit("resMessage","미등록 GW 입니다");
              this.loadingMac = false;
              return;
            }
            // console.log(res.data);
            this.smartbox_ID = res.data.mac;
            // this.smart_mac = Common.getMacMask(res.data.mac);
            this.model = res.data.model_id;
            
            this.company = {company_cd: res.data.company_cd, company_name: res.data.company};
            this.farm = {farm_cd: res.data.farm_cd, farm_name: res.data.farm};
            this.building = {building_cd: res.data.building_cd, building_name: res.data.building};
            this.room = {room_cd: res.data.room_cd, room_name: res.data.room + " " + res.data.farm };

            this.setdate = res.data.setdate;
            this.deldate = res.data.deldate;
            this.history = res.data.history; 
            this.hint = res.data.chguser + "님이 " + res.data.updated_at + "에 최종 수정";
            this.loadingMac = false;
            this.$store.commit("resMessage",res.message);
          } else {
            alert(res.message);
            this.loadingMac = false;
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류",err)
            this.loadingMac = false;
            // alert(err);
        }
      ); 
    },
    listModels() {
      Apis.listSmartboxModels({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.modelItems = res.data;
          } else {
            this.modelItems = [];
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
            alert(err);
        }
      ) 
    },
   comboCompanies() {
      Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = res.data;
          } else {
            this.companyItems = [];
            alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
      this.building = {};
      this.room = {};
    },
    comboFarms() {
      Apis.comboFarms({
        // company_cd: this.company && this.company.company_cd || "",
        company_cd: "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
        if (res.result) {
            this.farmItems = res.data;
          } else {
            this.farmItems = [];
            alert(res.message);  
          } 
          }).catch( (err) => {  // API 오류 처리
          console.log("comboFarms API 호출 오류",err)
          alert(err);
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {company_cd: this.farm.company_cd, company_name: this.farm.company_name};
      }
      this.building = {};
      this.room = {};
    },
    comboBuildings() {
      Apis.comboBuildings({
        company_cd:this.company &&  this.company.company_cd || "",
        farm_cd: this.farm && this.farm.farm_cd || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
        if (res.result) {
            this.buildingItems = res.data;
          } else {
            this.buildingItems = [];
            alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.building && this.building.company_cd) {
        this.company = {company_cd: this.building.company_cd, company_name: this.building.company_name};
      }
      if (this.building && this.building.farm_cd) {
        this.farm = {farm_cd: this.building.farm_cd, farm_name: this.building.farm_name};
      }
        this.room = {};
    },
    comboRooms() {
      Apis.comboRooms({
        company_cd:this.company &&  this.company.company_cd || "",
        farm_cd: this.farm && this.farm.farm_cd || "",
        building_cd: this.building && this.building.building_cd || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.roomItems = res.data;
          } else {
            this.roomItems = [];
            alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboRooms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeRoom() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.room && this.room.company_cd) {
        this.company = {company_cd: this.room.company_cd, company_name: this.room.company_name};
      }
      if (this.room && this.room.farm_cd) {
        this.farm = {farm_cd: this.room.farm_cd, farm_name: this.room.farm_name};
      }
      if (this.room && this.room.building_cd) {
        this.building = {building_cd: this.room.building_cd, building_name: this.room.building_name};
      }
    },
    smartMacMask(val) {
      this.smart_mac = Common.getMacMask(val);
    },

  }  // -----------methods 
}
</script>

<style lang="scss" scoped>
.normal-btn{
  color:white !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt  !important;
  font-weight: bold !important;
  background-color: #88191a !important;
}
</style>
